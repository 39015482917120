/*

*/


*{ 
  margin:0px;
  padding:0px;
  background-color: var(--main-color);
  color:var(--font-color);
}

.font-small { 
  font-size:.75rem
}
h2{ 
  font-size: 2.5rem;
}

.ten-percent-width{ 
  width:10%;
  height:auto;
}

.width-60-percent{ 
  width:60%;
}
.half-width { 
  width:50%;
}
.center-div-flex{
margin: 150px auto; 
width:300px;
border:2px solid var(--contrast-color);
padding:20px;
border-radius:10px;

}
.flex-container-el{ 
display:flex;
flex-direction: column;
align-items:center;
justify-content: center;

}



.flex-center-hor{ 
  display:flex;
  justify-content: center;
  align-items: center
}

.regularLabel{ 
  font-size:1.5rem;
}

.regularTextInputLogin{
font-size:1rem;
color:var(--contrast-color);
border:2px solid var(--contrast-color);

padding:5px;
}
.bottom-margin-25{ 
  margin-bottom: 25px;
}

.bottom-margin{ 
  margin-bottom:10px;
}

.margin-center{ 
  width:100%; 
  margin: 0 auto;
}
.regularTextInput{
 
  color:var(--contrast-color);
  border: 3px solid rgb(92, 92, 92);
  border-radius: 5px;
  padding:5px;
  }

  .width-170px{ 
    width:170px
  }
.regularTextInput:focus{
  outline:none;
  }

.outline-none{ 
  outline:none;
}

.green-submit-btnLogin { 
  font-size: 1rem;

  padding:5px;
  border:2px solid var(--contrast-color);
}

input[type="date"]::-webkit-clear-button {
  display: none;
}

.background-green { 
  background-color: var(--main-accent);
  color:black;
  font-weight: 600;
}

.green-submit-btnLogin:hover { 
  border:2px solid var(--main-accent);
}
.green-submit-btn:hover { 
  background-color: var(--button-color-hover);
 
}


.height-80-percent{ 
  height:80%;
}

.bottom-lead-page{ 
  height: 25vh;
}
.margin-top-10px{ 
  margin-top:10px;
}
.max-height{ 
  height:100%;
}

.max-height-vh{ 
  height:100vh;
}

.max-width{ 
  width: 100%;
}

.width-400px{ 
  width: 96%;
}

.width-800px{ 
  width: 800px;
}
#error_message{ font-size: 1.2rem;
}

.button-default{ 
  border:none;
  background-color: var(--secondary-color);
  padding:5px;
}

#left_panel_search_button{ 
  
}

::-webkit-datetime-edit-text { visibility:hidden;}
::-webkit-datetime-edit-month-field { color: white }
::-webkit-datetime-edit-day-field { color: white }

::-webkit-calendar-picker-indicator {  }

.upload-div{ 
  border: 2px dotted white;
  border-radius: 10px;

}

textarea{ 
  resize: vertical;
}

.green-submit-btn { 
  
  
  padding:5px;
  border:none;
  background-color: var(--button-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, .7);
 
}


.chat-bubble{ 
  word-wrap: break-word;
  max-width:  93%;
  display:inline-block;
  padding:10px;

}

.background-color-receive-chat{ 
  background-color: var(--button-color);
}

.commission-datepicker-container{ 
  padding:5px;
  border:none;
  background-color: var(--button-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, .7);
  margin-bottom: 5px;
}

.red-submit-btn:hover{
 background-color: var(--second-accent);
}

.red-submit-btn { 

  padding:5px;

}

.inline-el{ 
  display:inline;
}

.flex-center-column{ 
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.margin-left-50{ 
  margin-left:90px;
}

select{ 
  border:none;
}

select:focused{ 
  outline:none;
}

.box-border{ 
  border:2px solid var(--contrast-color);
  cursor:pointer;
  
}

.box-border-2{ 
  border-radius: 10px;
  padding:3px;
  border:2px solid var(--contrast-color);
  cursor:pointer;
  outline:none;
  
}

.flex-across{ 
  display:flex;
 
  width:100%;
}

.margin-left-10{ 
 margin-left:10px;
}

.box-border:hover{ 
  border:2px solid #90EE90 ;
  
  
}
.user_list{ 
  background-color:rgb(14, 14, 14);
}
.light-dark-background{
  background-color: var(--secondary-color);
}

.color-orange{ 
  color: var(--main-accent);
}

.dark-background-padding-5{
  padding:5px;
  border-radius: 10px;
}

.middle-tab-buttons{ 
  font-weight:bold;
  cursor:pointer;
  border:none;
  border-left:3px solid var(--third-color);
  border-radius: 0px;
  padding:10px;
}


input[type="date"]{ 
  font-family:  Arial, Helvetica, sans-serif;
  color-scheme: dark;

}
.flex-across-space-evenly{ 
  display:flex;
  justify-content: space-evenly;
}
.flex-across-space-between{
  display:flex;
  justify-content: space-between;
}
.middle-tab-buttons:hover{ 
  color: var(--main-accent);
  
}

.popup-tab-buttons{ 
  font-weight:bold;
  cursor:pointer;
  border:none;
  border-radius: 0px;
  padding:0px;
  font-size: 1rem;
}

.popup-tab-buttons:hover{ 
 color:var(--second-accent);
}

.table-dark-border-new{ 
 
  border: 2px solid var(--secondary-color);
}
.full-width{ 
  width:100%;
}
.table-wrapper {
  max-height: 19vh; /* Set maximum height for overflow */
  overflow-y: auto;
}
table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

th, td {
  padding: 8px;
  border: none;
  text-align: left;
}

.position-sticky{ 
  position:sticky;
}

thead th {
  position: sticky;
  top: 0;
  background-color: var(--third-color);
}


.real-red { 
  color:red;
}
.color-red{ 
color:var(--second-accent);
}

.hover-red:hover{ 
color:var(--second-accent);
}
.margin-right-20px{ 
  margin-right:20px;
}

.text-align-center{ 
  text-align: center;
}

.border-radius-10px{
  border:1px;
  border-radius: 10px;

}
.background-color-full-dark{ 
  background-color: var(--main-color);
}

.padding-20px{
  padding:15px;
}
.padding-10px{
  padding:10px;
}

.padding-1px{ 
  padding:5px
}
.no-border{ 
  border:none;
}
button{ 
  cursor: pointer;
}
.mediumtext{ 
  font-size: 1rem;
}

.text-align-left{
  width:100%;
  text-align: left;
}

.margin-top-5px{ 
  margin-top:5px;
}


.margin-top-50px{ 
  margin-top:45px
}
.margin-top-20px{ 
  margin-top:20px;
}


.loading{ 
  margin: 0 auto;
  width:100px;
  height:500px;
  
}

.width-500px{ 
  width:500px
}

.display-inline{ 
  display:inline-block;
}
.display-block{ 
  margin-top:auto;
}


.notification-bar { 
  width: 0;
  transition: width 0.3s ease; /* Smooth transition for width */
  
 
}

.notification-bar.active{ 
  width: 200px;
  
}

.side-bar-slide{ 
  width: 0; 
  transition:width 0.3s ease; 
  overflow-x:hidden;
  overflow-y:hidden;
  
}
.side-bar-slide.active{ 
  width: 150px;

}
.width-150px{ 
  width:150px
}


.width-200px{ 
  width:200px;
}


button, input {
  box-sizing: border-box;
}

select:focus{
  outline:none;
}

.middle-tab-buttons:focus{ 
  color:var(--main-accent);

}

.display-flex{ 
  display:flex;
}
.flex-basis-400px{ 
  flex-basis: 400px;
}

.flex-basis-550px{ 
  flex-basis: 50%;
}



.flex-basis-200px{ 
  flex-basis: 20%;
}
.flex-column-no-ho-centered{
  display:flex;
  flex-direction: column;
}
.border-top{ 
  border-top: 5px solid var(--secondary-color);
}
.height-20vh{ 
  height: 20vh;
}
.overflow-wrapper {
  max-height: 20vh; /* Set maximum height for overflow */
  overflow-y: auto;
}

.flex-one{ 
  flex: 1
}
.light-hover:hover{ 
  color: var(--main-color);
  cursor: pointer;
}

.padding-5px{ 
  padding:5px;
}

.flex-container-no-vert-center{
  display:flex;
  flex-direction: column;
  align-items:center;

}

input[type="checkbox"] {
 margin-top:10px;
}

.button-default-dark-background{ 
  border:none;
  background-color: var(--main-color);
  padding:5px;
}

input[type="date"]:focus{ 
  outline:none;
  
}
input[type="date"]{ 
  border:none;
  background-color: var(--secondary-color);
  font-size: 1rem;
  color:var(--contrast-color);
  
}

#search-results_table_body td{
  border:none;
  border-bottom: 3px solid var(--secondary-color);
  
  cursor:pointer;
}

.hover-secondary-color:hover{ 
  background-color: var(--secondary-color);
}
#search-results_table_body tr:hover td  {
background-color: var(--secondary-color);
pointer-events:auto;
}
.search-overflow-wrapper{
   max-height: 82vh; /* Set maximum height for overflow */
  overflow-y: auto;
}

.padding-left-none{
  padding-left: 0px;
}

.width-fit-content{ 
  width:fit-content;
  height:fit-content;
}

.padding-left-5px{
  padding-left: 5px;
}
.padding-left-10px{ 
  padding-left:10px;
}
.remove-default-google{ 
  background-color: var(--secondary-color);
  padding:0px;
  margin:0px;
}

.position-absolute{ 
  position:absolute;
}



.margin-bottom-10{ 
  margin-bottom:10px;
}

input[type="file"] button{ 
  
  
}
.block-display{ 
  display:block;
}

.border-bottom{ 
  border-bottom: 3px solid var(--secondary-color);
}

.border-none{ 
  border:none;
}

.dark-border-bottom{ 
  border-bottom: 5px solid var(--secondary-color);
}

.padding-left-2px{ 
  padding-left:2px;
}

.overflow-hidden{ 
  overflow:hidden;
  width:100%;
}



.hover-cursor-pointer:hover{
cursor:pointer;
}

.width-400px{ 
  width:400px;
}
.textarea {
  white-space: pre-wrap;
}

.flex-wrap{ 
  flex-wrap: none;
}

.flex-wrap-true{ 
  flex-wrap:wrap;
}
.height-200px{ 
  height:200px;
}
.height-250px{ 
  height:270px;
}

.margrin-bottom-5px{ 
  margin-bottom:5px;
}

.margin-left-5{ 
  margin-left:5px;
}
.position-sticky{ 
  position:sticky;
}


.flex-across-start{ 
  display:flex;
  justify-content: start;
}
.align-items-center{ 
  align-items: center;
}


.margin-left-1775px{ 
  margin-left:90%;
  width:150px;
}

.padding-bottom-5px{ 
  padding:5px;
}

.word-wrap{ 
  word-wrap: break-word;
}

.opacity{ 
  opacity: 0.9;
}

.z-index{ 
  z-index: 10;
}

.z-index-20{ 
  z-index:20;
}
.bg-color-black-1{ 
  background-color: var(--third-color);
}

.height-94vh{ 
  height:94vh
}

.text-decoration-none{ 
  text-decoration: none;
}

.secondary-color{ 
  background-color: var(--secondary-color);
}

.width-75-percent{
 width:75%;
}

.full-height-overflow-y{ 
  height: 85vh;
  overflow-y: scroll;
}

.full-height-overflow-y-90{ 
  height:90vh;
  overflow-y:scroll
}


.text-align-right-new{ 
  width: 100%;
  text-align: right;
}

.margin-10px{ 
  margin:10px;
}

.height-100{ 
  height: 97vh;
}

.justify-content-space-between{ 
  justify-content: space-between;
}

.margin-right-320px{ 
  margin-right: 275px;
}

.pac-container {
  width:500px;
  background-color: var(--third-color); /* Background color of the dropdown */
  color: #fff;           /* Text color */
  border-radius: 5px;    /* Rounded corners */
}

/* Custom styles for individual autocomplete items */
.pac-item {
 
  background-color: var(--third-color); /* Background color of the items */
  color: #fff;            /* Text color */
}
.pac-item > span {
 
  background-color: var(--third-color); /* Background color of the items */
  color: #fff;            /* Text color */
}

.pac-item:hover {
  background-color: var(--third-color); /* Background color on hover */
}

.pac-item-query {
  width:100%;
  color: #fff; /* Text color for the matched query */
}
.font-size-20px{ 
  font-size: 20px;
}
.width-400px{ 
  width:400px;
}

.width-380px{ 
  width: 370px;
}

.bg-color-grey{ 
  background-color: grey;
}

.text-align-left-no-width{ 
  text-align:left;
  width:300px;
}
.checkbox{ 
  border:1px solid var(--third-color);
}

.circular-checkbox input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.circular-checkbox{ 
  border-radius: 50%;
}

.width-5-percent{ 
  width:5%
}

.height-300px{ 
  height:300px;
}
.height-n-300px{ 
  height:330px;
}

.margin-left-200px{ 
  margin-left:200px;
}


/* Alternatively, if you want to target specific buttons, inspect them to find their classes or IDs and hide them individually */
.rbc-btn  {
  padding:0.5rem;
}

.rbc-button-link{
  pointer-events: none;
}

.fade-in { 
  opacity: .5;
  transition: opacity .5s ease-out;
}

.fade-in:hover { 
  opacity: 1;
  color:var(--main-accent)
}

.file-input { 
  display:none;
}

#drop_zone{ 
  padding:50px;
  cursor: pointer;
  border:2px dashed white;
  margin:10px;
  border-radius: 10px;
}


.flex-evenly{ 
  display:flex;
  justify-content: space-around;
}

.width-20px{ 
  width:5px;
}


.width-25-percent{ 
  width: 250px;
}
.grid-container-dashboard {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Creates 3 equal-width columns */
  gap: 20px; /* Adds space between grid items */
}
.stat{ 
  background-color: var(--secondary-color) ;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .7);
  padding:10px;
}
.stat >  h2, .stat >  h3, .stat > h4{ 
  background-color: var(--secondary-color) ;
}



.selectedUser-chat { 
  padding:5px;
  border-radius:5px;
 background-color: var(--button-color); 
}


.notification-chat-bg{ 
  background-color: var(--button-color); 
}
.position-right { 
  right:10px;
}
.search-load {
margin-left: 30vw;
}

.load-size{ 
  width:100px;
  height:auto; 
}

.wdith-70px{ 
  width:70px
}

.width-10-percent{ 
  width:10%;
}

.width-5-percent{ 
  width:5%;
}
.width-20px{ 
  width:130px;
}
.blurred {
  filter: blur(15px); /* Adjust the value to change the blur intensity */
}

.ticker{ 
  white-space: nowrap;
  display: inline-block;
  color: #f2f2f2;
  animation: slide 20s linear infinite;
}
.padding-right-10px{ 
  padding-right:10px;
}
.overflow-50-percent{ 
  overflow-y:scroll; 
 height: 70vh;
}
.overflow-height{ 
  overflow-y:scroll;
  height:200px;
}


.overflow-user-list{ 
  overflow-y: scroll; 
  max-height:470px;
}

.long-text {
  display:inline-block;
  text-align:left; /* Right-align long text */
}


.overflow-chatContainer{ 
  
  overflow-x: hidden; 
  height:500px; 
  overflow-y:scroll;
}

.max-height-sb{ 
  height: 100%;
  width:100%;
  
}
.height-100px{ 
  height:100px;
}
.max-height{ 
  height:100%;
}
@keyframes slide {
  0% {
      transform: translateX(100%);  /* Start outside the right edge */
  }
  100% {
      transform: translateX(-100%);  /* Move outside the left edge */
  }
}

.followups-table{ 
  overflow: scroll;
  max-height: 530px;
  overflow-x:hidden
}

.no-borders{ 
  borders:none;
}

.lead-card{ 
 
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.9);
 
  z-index:  0;
  
}

.grid-container{ 
 
  width:100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr; 
  
  
}

.grid-child{ 
  /*max-height:230px;*/
  height:140px;
  margin-top:-90px;

  
}

.lead-card:nth-child(0) {
 opacity: .50;
 
}



.lead-card:nth-child(1) {
  margin-top: -1px;
  opacity: .70;
  z-index:0;
  
}
.lead-card:nth-child(2)  {
  
  
  opacity: .90;
 }

 .lead-card:hover{ 
  opacity:100%;
 }

.blur { 
  animation: blurEffect 5s ease infinite;
}

.lead-card:nth-child(3) {

 z-index:1;
 
}

@keyframes blurEffect {
  0% {
    filter:blur(3.5px);
  }
  50% {
    filter:blur(10.5px);
  }
  100% {
    filter:blur(3.5px);
  }
}





.margin-left_50{ 
  right: 17.5%;
  
}

.max-width-180{ 
  width: 25vh;

}

.follow-up-height{ 
  max-height:800px;
}


.z-index-2{ 
  overflow: hidden;
}
.word-wrap{ 
  word-wrap:break-word;
}

.max-witdh-75{ 
  max-width: 1200px;
}

.overflow-view-ticket{ 
  overflow-y: scroll;
  height: 80vh;
}

.max-width-500{ 
  width: 100%;
}

.width-95-percent{ 
  width: 95%;
}

.height-half{ 
  height: 400px;
  overflow-y:scroll;

}

.height-80vh{ 
  height:80vh
}

.commissions-popup{ 
  height: 35%; 
  width: 85vw;
  overflow-y:scroll;
}

.font-size-12px{ 
  font-size:12px;
}

.overflow-x{ 
  overflow-x:hidden;
  overflow-y: hidden;
}

.width-700px{ 
  width:80vw;
}



.policy-type-control{ 
  height:200px; 
  overflow-y:scroll
}

.img-size-medium{ 
  width:100%;
  height:auto;
}

.gird-container-household-contact{ 
  display:grid; 
  grid-template-columns: 2fr 2fr 1fr 1fr;
  grid-template-rows: 1fr 1fr ;
  max-height: 60px;
}

.middle-width{ 
  max-width: 60vw;
}
.max-width-half{ 
  max-width:50%;
}

.background-img { 
  background-image: url('images/avatar.png');
  background-size:  50% 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.grid-management { 
  display:grid; 
  grid-template-columns: 1fr 1fr 1fr;
  width:100%;
  gap:10px;
}

.management-nav{ 
  background-color: #535353;
}


.margin-left-neg-20{ 
  margin-left: -800px;
}




@keyframes shake {
  0% {
    transform: translateX(0px);
  }
  25% {
    transform: rotate(25deg);
    
    
  
   
  }
  50% {
    
    
  }
  75% {
    transform: rotate(-25deg);
    
  }
  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: shake 0.6s ease-in-out ;
  /* Optionally, set the animation to repeat infinitely */
  animation-iteration-count: infinite;
}



.timeSheetPopupContainer{ 
  display:grid; 
  grid-template-columns: repeat(4, 1fr);
  gap:25px;
}


